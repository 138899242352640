"use client";
import { getImageProps, getImageAspectRatio } from "@/cms/images/getImageProps";
import { SanityImage } from "@/cms/images/types";
import { COOKIE_UNIVERSE } from "@/routing/routing";
import { setCookie } from "@cappelendamm/cookie";
import Image from "next/image";
import { useRouter } from "next/navigation";

type Universe = {
  tag: string;
  title: string;
  image?: SanityImage;
};

export const UniversePicker = ({ tag, title, image }: Universe) => {
  const { refresh } = useRouter();

  return (
    <button
      className="grid justify-center gap-2"
      onClick={() => {
        setCookie({ name: COOKIE_UNIVERSE, value: tag });
        refresh();
      }}
    >
      {image ? (
        <Image
          className="aspect-square h-[200px] justify-self-center rounded-full"
          {...getImageProps({
            ...image,
            ...getImageAspectRatio({ height: 200, aspectRatio: 1 / 1 }),
          })}
        />
      ) : (
        <svg
          className="aspect-square h-[200px] justify-self-center text-[48px]"
          viewBox="0 0 64 64"
        >
          <text x={0} y={48}>
            ❓
          </text>
        </svg>
      )}
      <span>{title}</span>
    </button>
  );
};
